<script setup lang="ts">
const { y } = useNinjaWindowScroll();
</script>
<template>
  <div class="group/landing overflow-hidden" :class="y > 60 ? 'scrolled' : ''">
    <TairoCollapseLayout :collapse="false" :circular-menu="false">
      <AppLandingNavbar />
      <main>
        <slot />
      </main>
    </TairoCollapseLayout>
  </div>
</template>
